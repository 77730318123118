import { ComponentActions } from '@core/bootstrap'
import { forTarget } from '@core/content'
import { gqlQuery } from '@core/gqlQuery'
import { mount } from '@core/mount'
import { on } from '@core/on'
import axios from 'axios'
import camelCaseKeys from '@lib/camelCaseKeys'

type LeadFormData = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  street1: string,
  city: string,
  state: string,
  postalCode: string,
  destination: string,
  ipAddress: string,
  productURL: string,
  referralSource: string
}

const CreateLeadQuery = `#graphql
  mutation CreateLead ($payload: CreateLeadPayload) {
    createLead (payload: $payload) {
      success
    }
  }
`

export default function CreateLeadNew (root: Element): ComponentActions {
  async function getIPAddress (): Promise<string> {
    try {
      const { data: { ip } } = await axios.get('https://api.ipify.org/?format=json')
      return ip
    } catch (_error) {
      return '0.0.0.0'
    }
  }

  async function createLead (event: CustomEvent): Promise<void> {
    event.preventDefault()

    const formData = new FormData(event.target as HTMLFormElement)
    const data = camelCaseKeys<any>(Object.fromEntries(formData as any))

    const variables: { payload: LeadFormData } = {
      payload: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        street1: data.street1,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        destination: data.destination,
        ipAddress: await getIPAddress(),
        productURL: document.documentURI,
        referralSource: data.referralSource || 'soundly',
      },
    }

   gqlQuery({ query: CreateLeadQuery, variables }).subscribe(resp => console.info(resp))
  }

  return {
    start: mount(root, on('create-lead', createLead)),
  }
}
